import { useLookupStore } from "@/stores/lookup";
import { useVandanlaegLookupStore } from "@/stores/vandanlaeg-lookup";
import { RouteLocationNormalized } from "vue-router";
import { Lookup } from "@/models/lookup";
import { unref } from "vue";
import { fetchGet } from "@/utils/networking/httpclient";
import { Guid } from "guid-typescript";
import { IMwTableFetchItemsParams } from "@/utils/interfaces";
import { VirksomhedsTypeEnum } from "@/models/virksomhed/virksomhedsTypeEnum";
import UrlBuilder from "@/utils/networking/urlBuilder";

export function createGuid() {
  if (crypto.randomUUID) {
    return crypto.randomUUID();
  }

  return Guid.create().toString();
}

export function downloadData(data: any, mimeType: string, filename: string) {
  const anchor = document.createElement("a") as HTMLAnchorElement;
  const blob = window.URL.createObjectURL(
    new Blob([data], {
      type: mimeType
    })
  );

  anchor.setAttribute("download", filename);
  anchor.href = blob;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  window.URL.revokeObjectURL(blob);
}

export function writeCanvasImageToClipboard(canvasElement: HTMLCanvasElement) {
  const newCanvas = document.createElement("canvas");
  newCanvas.width = canvasElement.width;
  newCanvas.height = canvasElement.height;

  const ctx = newCanvas.getContext("2d");
  if (ctx) {
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    //fordi
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);
    ctx.restore();

    //call its drawImage() function passing it the source canvas directly
    ctx.drawImage(canvasElement, 0, 0);
  }

  newCanvas.toBlob(blob => {
    //@ts-ignore
    const item = new ClipboardItem({ "image/png": blob });
    //@ts-ignore
    navigator.clipboard.write([item]);
  }, "image/png");
}

export function downloadCanvasAsImage(canvasElement: HTMLCanvasElement, filename: string = "graf.png") {
  canvasElement.toBlob(blob => {
    if (blob !== null) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      link.click();
    }
  }, "image/png");
}

export function loadUrl(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("error", () => reject(script), false);
    document.body.appendChild(script);
  });
}

export function loadUrls(urls: string[]) {
  return Promise.all(urls.map(loadUrl));
}

export async function fetchVandanlaegLookup(url: string, key: string, force: boolean = false) {
  const vandanlaegLookupStore = useVandanlaegLookupStore();

  if (vandanlaegLookupStore[key] === undefined) {
    console.error("Could not fetch lookup: %o, because key does not exist in state: %o", url, key);
    return;
  }

  if (force || vandanlaegLookupStore[key].length === 0) {
    const response = await fetchGet(url);
    vandanlaegLookupStore.updateLookupState(response.data, key);
  }
}

export async function fetchLookup(url: string, key: string, force: boolean = false) {
  const lookupStore = useLookupStore();

  if (lookupStore[key] === undefined) {
    console.error("Could not fetch lookup: %o, because key does not exist in state: %o", url, key);
    return;
  }

  if (force || (lookupStore[key] as Lookup[]).length === 0) {
    const response = await fetchGet(url);
    lookupStore.updateLookupState(response.data, key);
  }
}

export function scrollToElement(ref: any, callback?: any) {
  if (ref) {
    setTimeout(() => {
      ref.scrollIntoView({
        behavior: "smooth",
        block: "center" //MILDEV-634 Scroll item på lister skal ligge i midten
      });
      if (callback) {
        callback();
      }
    }, 10);
  }
}

export function calculateDecimal(d1: number, d2: number, method?: string): string {
  d1 = parseFloat(d1.toString().replace(".", "").replace(",", "."));
  d2 = parseFloat(d2.toString().replace(".", "").replace(",", "."));
  if (!method || method.toLowerCase() === "subtract") {
    return (d1 - d2).toFixed(2).replace(".", ",");
  } else {
    return (d1 + d2).toFixed(2).replace(".", ",");
  }
}

type refType = HTMLInputElement | HTMLButtonElement | null;

export function isTrackingAllowed(ref: refType): boolean {
  if (!ref) {
    throw new Error("No template ref were provided");
  }

  const form: HTMLElement | null = ref.form;

  if (form == null) {
    throw new Error(`The input field isn't a descendant of a HTML form element.`);
  }

  return form.dataset["trackChanges"] === "true";
}

/**
 * Attempts to extract the related entity ID from the HTML form element
 * the provided input element is a descendant of. The id should be defined
 * inside a HTML5 data-attribute using the name 'data-related-entity-id'.
 *
 * @param ref A reference to the JS DOM element of the input field associated with a form.
 * @throws Throws an error if the provided input field is NOT a descendant of a form element.
 */
export function getRelatedEntityId(ref: refType): string {
  if (!ref) {
    throw new Error("No template ref were provided");
  }

  const form: HTMLElement | null = ref.form;

  if (form == null) {
    throw new Error(`Cannot compute the related entity ID of the input. The input field isn't a descendant of a HTML form element.`);
  }

  const id = form.dataset["relatedEntityId"];

  return id != undefined ? id : "";
}

/**
 * Attempts to resolve the name of the route by prefixing the
 * current routing area i.e: "virksomhed", "landbrug" etc.
 *
 * @param name The unprefixed name of the route.
 * @param route
 */
export function resolveRouteNameWithArea(name: string, route: RouteLocationNormalized): string {
  let area: string = "";
  const offset = route.matched.length - 1;

  for (let index = offset; index >= 0; index--) {
    const meta = route.matched[index].meta;
    if (meta.area) {
      area = meta.area;
      break;
    }
  }

  if (!area || area.length === 0) {
    console.warn(`Failed to resolve area prefix for route with name: ${name}.`);
    return name;
  }

  return `${area}-${name}`;
}

export function isLookupDmaValid(id: string, dmaLookups: Lookup[]) {
  const lookup = dmaLookups.find((x: Lookup) => x.id === id) as Lookup;
  if (lookup) {
    return lookup.dmaKode;
  }
  return false;
}

export function getMutatedSagsbehandlerList(list: Lookup[], item: any, identifier: string = "id", label: string = "navn") {
  //Hvis f.eks. det pågældende tilsyn/whatever har en deaktiveret bruger, er den som udgangspunkt ikke med i lookuplisten. Vi tilføjer derfor brugeren så den kan findes og vælges i dropdown.

  if (item && item[identifier]) {
    const existingItem = list.find((x: any) => x[identifier] == item[identifier]);
    if (!existingItem) {
      return [
        ...list,
        {
          [identifier]: item[identifier],
          navn: item[label]
        }
      ];
    } else {
      return list;
    }
  } else {
    return list;
  }
}

export async function validate(vuelidate: any, scroll = true) {
  const isValid = await unref(vuelidate).$validate();

  if (!isValid && scroll) {
    const element = document.querySelector(".has-danger");

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      const focusableElement: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null =
        element.querySelector("input, textarea, select");
      if (focusableElement) {
        focusableElement.focus({ preventScroll: true });
      }
    }
  }

  return isValid;
}

export const convertCommaToDotAndRemoveSeperator = (value: string | number) => {
  if (value) {
    let stringValue = String(value);

    stringValue = stringValue.replaceAll(".", "");

    const hasComma = stringValue.includes(",");
    if (hasComma) {
      return stringValue.replace(",", ".");
    }

    return stringValue;
  }
  return value;
};

export function getLookupValue(value: string, lookups: Lookup[], name = "navn", key = "id") {
  const lookupItem = lookups.find(lookup => lookup[key] === value);

  return lookupItem ? lookupItem[name] : "";
}

function createMwTableFetchItemsUrlBuilder(urlBuilder: UrlBuilder | string, params: IMwTableFetchItemsParams): UrlBuilder {
  if (typeof urlBuilder === "string") {
    urlBuilder = new UrlBuilder(urlBuilder);
  }

  Object.entries(params).forEach(([key, value]) => {
    if (key === "filteredColumns") {
      (urlBuilder as UrlBuilder).addParameterWithArrayValue(key, value);
    } else {
      (urlBuilder as UrlBuilder).addParameter(key, value);
    }
  });

  return urlBuilder;
}

export function createMwTableFetchItemsUrl(urlBuilder: UrlBuilder | string, params: IMwTableFetchItemsParams): string {
  return createMwTableFetchItemsUrlBuilder(urlBuilder, params).generateUrlWithParams();
}

export function nameof<TEntity>(name: Extract<keyof TEntity, string>): string {
  return name;
}
