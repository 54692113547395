import { defineStore } from "pinia";
import { VandanlaegLookupState } from "@/stores/statetypes";
import { fetchVandanlaegLookup } from "@/utils/utils";
import { fetchGet } from "@/utils/networking/httpclient";
import { Lookup } from "@/models/lookup";

export const useVandanlaegLookupStore = defineStore("vandanlaeg-lookup", {
  state: (): VandanlaegLookupState => {
    return {
      jupiteraktivstatus: [],
      beliggenhedskommune: [],
      analysegodkenderkommune: [],
      jupiteranlaegsart: [],
      jupitervandtype: [],
      jupiterindvindingsformaal: [],
      jupiterkoordinatmetode: [],
      jupiterkontrolprogram: [],
      sagsbehandler: [],
      vandanlaeg: [],
      kontaktpersonTyper: [],
      kontaktpersonTyperJupiter: [],
      jupiterindtaganvendelse: [],
      jupiterpejlesituation: [],
      jupiterpejlemetode: [],
      jupitertilladelsekilde: [],
      jupitertilladelseformaal: [],
      jupitertilladelsetype: [],
      vandanlaegtilsynstype: [],
      vandanlaeghaandhaevelsetype: [],
      jupiterindvindingsmetode: [],
      jupiterforsyningsart: [],
      jupiterindvinderenhed: [],
      jupitermuligevirksomhedstype: [],
      jupiterboringsanvendelse: [],
      jupitervirksomhedstype: [],
      jupiterLaboratorie: [],
      jupiterFormaal: [],
      jupiterOmfang: [],
      boringer: [],
      jupiterReferencepunkt: [],
      jupiterKotesystem: [],
      jupiterPejlekatogori: [],
      jupiterPejleKvalitet: [],
      jupiterPejleProjekt: [],
      jupiterPejleEkstremer: [],
      jupiterAnalyseFrekvensLov: [],
      jupiterAnalyseFrekvensProevetagningssted: [],
      jupiterAnlaegslov: [],
      jupiterAndreForanstaltninger: [],
      jupiterBoringslov: [],
      jupiterstandatstoffer: [],
      jupiterstandatenheder: [],
      jupiterProjekt: []
    };
  },
  actions: {
    updateLookupState(data: any, key: string) {
      (this as any)[key] = data;
    },
    async fetchBasisLookups({ kundeId, indvindingsFormaalId }: any) {
      const promises = [
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiteraktivstatus", "jupiteraktivstatus"),
        fetchGet("MWVandanlaegLookUp/list/kommune").then(r => {
          this.beliggenhedskommune = r.data;
          this.analysegodkenderkommune = r.data;
        }),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiteranlaegsart", "jupiteranlaegsart"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitervandtype", "jupitervandtype"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindvindingsformaal", "jupiterindvindingsformaal"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterKoordinatMetode", "jupiterkoordinatmetode"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterCTRLPROGRAM", "jupiterkontrolprogram"),
        fetchVandanlaegLookup("sagsbehandler/list/" + kundeId + "?type=Vandanlaeg", "sagsbehandler"),
        fetchVandanlaegLookup("Vandanlaeg/HentVandanlaegListe?kundeid=" + kundeId, "vandanlaeg"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitervirksomhedstype", "jupitervirksomhedstype")
      ];

      await Promise.all(promises);
    },

    async fetchKontaktpersonLookups() {
      await Promise.all([fetchVandanlaegLookup("kontaktpersontype/listjupiter", "kontaktpersonTyperJupiter")]);
    },

    async fetchBoringLookups() {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindtaganvendelse", "jupiterindtaganvendelse"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterpejlesituation", "jupiterpejlesituation"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterpejlemetode", "jupiterpejlemetode"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindvindingsmetode", "jupiterindvindingsmetode"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterBoringsAnvendelse", "jupiterboringsanvendelse"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterReferencepunkt", "jupiterReferencepunkt"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterKotesystem", "jupiterKotesystem"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterPejlekatogori", "jupiterPejlekatogori"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterPejleKvalitet", "jupiterPejleKvalitet"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterPejleProjekt", "jupiterPejleProjekt"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterPejleEkstremer", "jupiterPejleEkstremer")
      ]);
    },

    async fetchTilladelseLookups(virksomhedId: string) {
      const promises = [
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitertilladelsekilde", "jupitertilladelsekilde"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitertilladelseformaal", "jupitertilladelseformaal"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitertilladelsetype", "jupitertilladelsetype"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitervandtype", "jupitervandtype"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindvindingsformaal", "jupiterindvindingsformaal"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindtaganvendelse", "jupiterindtaganvendelse"),
        fetchVandanlaegLookup(`Boring/listsimple/${virksomhedId}`, "boringer", true),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitervirksomhedstype", "jupitervirksomhedstype")
      ];

      await Promise.all(promises);
    },

    async fetchBoringListSimple(virksomhedId: string) {
      await fetchVandanlaegLookup(`Boring/listsimple/${virksomhedId}`, "boringer", true);
    },

    async fetchPaabudLookups({ commit, state }: any) {
      await Promise.all([]);
    },

    async fetchTilsynLookups({ kundeId }: any) {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/vandanlaegtilsynstype", "vandanlaegtilsynstype"),
        fetchVandanlaegLookup("sagsbehandler/list/" + kundeId + "?type=Vandanlaeg", "sagsbehandler")
      ]);
    },

    //Ingen endnu
    async fetchHaandhaevelseLookups({ kundeId }: any) {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/vandanlaegHaandhaevelsetype", "vandanlaeghaandhaevelsetype"),
        fetchVandanlaegLookup("sagsbehandler/list/" + kundeId + "?type=Vandanlaeg", "sagsbehandler")
      ]);
    },

    async fetchOppumpedeMaengdeLookups() {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindvindingsmetode", "jupiterindvindingsmetode"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterforsyningsart", "jupiterforsyningsart"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterindvinderenhed", "jupiterindvinderenhed")
      ]);
    },

    async fetchVirksomhedstypeByIndvindingsformaalId(indvindingsFormaalId: any) {
      await Promise.all([
        fetchVandanlaegLookup(
          "MWVandanlaegLookUp/list/jupitermuligevirksomhedstype/" + indvindingsFormaalId,
          "jupitermuligevirksomhedstype",
          true
        )
      ]);
    },

    async fetchImporteredeEksporteredeMaengdeLookups({ kundeId }: any) {
      await Promise.all([fetchVandanlaegLookup("Vandanlaeg/HentVandanlaegListe?kundeid=" + kundeId, "vandanlaeg")]);
    },

    async fetchAnalysefrekvensLookups() {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterAnalyseFrekvensLov", "jupiterAnalyseFrekvensLov"),
        fetchVandanlaegLookup(
          "MWVandanlaegLookUp/list/jupiterAnalyseFrekvensProevetagningssted",
          "jupiterAnalyseFrekvensProevetagningssted"
        ),
        fetchVandanlaegLookup(`MWVandanlaegLookUp/list/jupiterstandatstoffer`, "jupiterstandatstoffer"),
        fetchVandanlaegLookup(`MWVandanlaegLookUp/list/jupiterstandatenheder`, "jupiterstandatenheder")
      ]);
    },

    async fetchAnlaegspaabudLookups() {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterAnlaegslov", "jupiterAnlaegslov"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterAndreForanstaltninger", "jupiterAndreForanstaltninger")
      ]);
    },

    async fetchBoringspaabudLookups(virksomhedId: string) {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterBoringslov", "jupiterBoringslov"),
        fetchVandanlaegLookup(`Boring/listsimple/${virksomhedId}`, "boringer")
      ]);
    },

    async fetchVandproeveLookups() {
      await Promise.all([
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupitervirksomhedstype", "jupitervirksomhedstype"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterLaboratorie", "jupiterLaboratorie"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterFormaal", "jupiterFormaal"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterOmfang", "jupiterOmfang"),
        fetchVandanlaegLookup("MWVandanlaegLookUp/list/jupiterProjekt", "jupiterProjekt")
      ]);
    }
  },
  getters: {
    vandanlaegLookups(): any {
      return {
        jupitervirksomhedstype: this.jupitervirksomhedstype
      };
    },
    basisLookups(): any {
      return {
        jupiteraktivstatus: this.jupiteraktivstatus,
        beliggenhedskommune: this.beliggenhedskommune,
        analysegodkenderkommune: this.analysegodkenderkommune,
        jupiteranlaegsart: this.jupiteranlaegsart,
        jupitermuligevirksomhedstype: this.jupitermuligevirksomhedstype,
        jupitervirksomhedstype: this.jupitervirksomhedstype,
        jupitervandtype: this.jupitervandtype,
        jupiterindvindingsformaal: this.jupiterindvindingsformaal,
        jupiterkoordinatmetode: this.jupiterkoordinatmetode,
        jupiterkontrolprogram: this.jupiterkontrolprogram,
        sagsbehandler: this.sagsbehandler,
        vandanlaeg: this.vandanlaeg
      };
    },
    boringLookups(): any {
      return {
        jupiterindtaganvendelse: this.jupiterindtaganvendelse,
        jupiterpejlesituation: [new Lookup(), ...this.jupiterpejlesituation],
        jupiterpejlemetode: [new Lookup(), ...this.jupiterpejlemetode],
        jupiterboringsanvendelse: this.jupiterboringsanvendelse,
        jupiterReferencepunkt: this.jupiterReferencepunkt,
        jupiterKotesystem: [new Lookup(), ...this.jupiterKotesystem],
        jupiterPejlekatogori: [new Lookup(), ...this.jupiterPejlekatogori],
        jupiterPejleKvalitet: [new Lookup(), ...this.jupiterPejleKvalitet],
        jupiterPejleProjekt: [new Lookup(), ...this.jupiterPejleProjekt],
        jupiterPejleEkstremer: [new Lookup(), ...this.jupiterPejleEkstremer],
        jupiterindvindingsmetode: [new Lookup(), ...this.jupiterindvindingsmetode]
      };
    },
    kontaktpersontypeLookups(): any {
      return {
        kontaktpersonTyper: this.kontaktpersonTyper
      };
    },
    tilladelseLookups(): any {
      return {
        jupitervandtype: [new Lookup(), ...this.jupitervandtype],
        jupitertilladelsekilde: [new Lookup(), ...this.jupitertilladelsekilde],
        jupitertilladelseformaal: this.jupitertilladelseformaal,
        jupitertilladelsetype: [new Lookup(), ...this.jupitertilladelsetype],
        jupitervirksomhedstype: [new Lookup(), ...this.jupitervirksomhedstype],
        jupiterindvindingsformaal: [new Lookup(), ...this.jupiterindvindingsformaal],
        jupiterindtaganvendelse: this.jupiterindtaganvendelse,
        boringer: this.boringer
      };
    },
    paabudLookups(): any {
      return {};
    },
    tilsynLookups(): any {
      return {
        vandanlaegtilsynstype: this.vandanlaegtilsynstype,
        sagsbehandler: this.sagsbehandler
      };
    },
    haandhaevelseLookups(): any {
      return {
        vandanlaeghaandhaevelsetype: this.vandanlaeghaandhaevelsetype,
        sagsbehandler: this.sagsbehandler
      };
    },
    oppumpedeMaengdeLookups(): any {
      return {
        jupiterindvindingsmetode: this.jupiterindvindingsmetode, // er lidt i tvivl
        jupiterforsyningsart: this.jupiterforsyningsart,
        jupiterindvinderenhed: this.jupiterindvinderenhed
      };
    },
    importeredeEksporteredeMaengdeLookups(): any {
      return {
        vandanlaeg: this.vandanlaeg
      };
    },
    analysefrekvensLookups(): any {
      return {
        jupiterAnalyseFrekvensLov: this.jupiterAnalyseFrekvensLov,
        jupiterAnalyseFrekvensProevetagningssted: this.jupiterAnalyseFrekvensProevetagningssted,
        jupiterstandatstoffer: this.jupiterstandatstoffer,
        jupiterstandatenheder: this.jupiterstandatenheder
      };
    },
    anlaegspaabudLookups(): any {
      return {
        jupiterAnlaegslov: this.jupiterAnlaegslov,
        jupiterAndreForanstaltninger: [new Lookup(), ...this.jupiterAndreForanstaltninger]
      };
    },
    boringpaabudLookups(): any {
      return {
        jupiterBoringslov: this.jupiterBoringslov,
        boringer: this.boringer
      };
    },
    vandproeveLookups(): any {
      return {
        jupitervirksomhedstype: this.jupitervirksomhedstype,
        jupiterLaboratorie: this.jupiterLaboratorie,
        jupiterFormaal: this.jupiterFormaal,
        jupiterOmfang: this.jupiterOmfang,
        jupiterProjekt: this.jupiterProjekt
      };
    },
    kontaktpersonerLookups(): any {
      return {
        kontaktpersonTyperJupiter: this.kontaktpersonTyperJupiter
      };
    }
  }
});
