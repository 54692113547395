export default class Lokalitet {
  id: string = "";
  adresse: string = "";
  matrikler: Matrikel[] = [];
  dawaAdresseId: string = "";
  bygningerOgAnlaeg: BygningOgAnlaeg[] = [];
  ejere: Ejer[] = [];
}

class Matrikel {
  ejerlavsnavn: string = "";
  ejerlavKode: string = "";
  matrikelNr: string = "";
  ejdNr: string = "";
  bfeNr: string = "";
  fraBBRMAT: boolean = false;
}

class BygningOgAnlaeg {
  id: string = "";
  navn: string = "";
  nr: number = 0;
  bbrBygningAnlaegType: number = 0;
  anlaegType: string = "";
  anvendelse: string = "";
  placering: string = "";
  opfoerelsesAar: number = 0;
  areal: number = 0;
  rumfang: number = 0;
  stoerrelsesklasse: string = "";
  fabrikationstype: string = "";
  fabrikationsnummer: string = "";
  punktWkt?: string = "";
  omridsWkt?: string = "";
  kortLabel: string = "";
}

class Ejer {
  bfeNummer: string = "";
  kommuneKode: string = "";
  primaerKontakt: boolean = true;
  ejerAndel: string = "";
  ejerType: number = 0;
  ejerForholdskode: string = "";
  adresseBeskyttelse: boolean = true;
  ejerNavn: string = "";
  ejerCONavn: string = "";
  ejerAdresse: string = "";
  ejerAdresseUdvidet: string = "";
  ejerPostnrBy: string = "";
  ejerCvrnr: string = "";
  ejerTypeBeskr: string = "";
  ejerForholdBeskr: string = "";
}
